$staticUrl: 'https://static.likehostels.ru';
@import "variables";

.del {
  text-decoration: line-through;
}

.link-dashed {
  text-decoration: none;
  border-bottom: 1px dashed $link-color;
  cursor: pointer;
  color: $link-color;

  &:hover, &:focus {
    color: $link-hover-color;
    border-color: $link-hover-color;
  }
}

.nav > li > a {
  &, &:not([href]):not([tabindex]) {
    color: $nav-text-color;
  }
}
