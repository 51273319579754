$staticUrl: 'https://static.likehostels.ru';
error-404 {
  .middle-box {
    max-width: 430px;
    z-index: 100;
    margin: 0 auto;
    padding-top: 40px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .middle-box h1 {
    font-size: 170px;
  }

  > .container {
    max-width: 800px;
  }
}
