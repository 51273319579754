$staticUrl: 'https://static.likehostels.ru';
@import "inspinia/scss/variables";

franchisee-employee-permissions-view {
  table.table {
    width: auto;

    td, th {
      &:not(:first-child):not(:last-child) {
        padding-left: 20px;
        padding-right: 20px;
      }
      &:first-child {
        padding-left: 0px;
      }
      border-top: none;
      border-bottom: none;
      &:not(:last-child) {
        border-right: 1px solid $border-color;
      }
    }
    td {
      text-align: left;
    }

    .label {
      display: block;
      width: 100%;
      padding-top: 3px;
      padding-bottom: 5px;
      max-width: 75px;
    }
  }
}
