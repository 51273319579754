$staticUrl: 'https://static.likehostels.ru';
@import "app/variables";
@import "app/mixins";

app-login {
  > .container {
    max-width: 800px;
    @media (max-width: 768px) {
      max-width: 470px;
    }

    @include media-breakpoint-up(md) {
      @media (min-height: 400px) {
        padding-top: 20px;
      }
      @media (min-height: 500px) {
        padding-top: 100px;
      }
    }
  }
}
