$staticUrl: 'https://static.likehostels.ru';
layout-breadcrumb {
  .breadcrumb {
    li.active {
      span, a {
        font-weight: bold;
      }
    }
  }
}
