$staticUrl: 'https://static.likehostels.ru';
@import "inspinia/scss/variables";

layout-sidebar-hotel {
  > ul.nav {
    > li.active {
      border-color: $yellow;

      > a {
        background: lighten($yellow, 3%);
        color: white;
        &:hover, &:focus {
          background: $yellow !important;
          color: white;
        }
      }

      > ul.nav-second-level {
        background-color: $nav-bg;
        > li {
          &, &.active {
            &, > a:focus, > a:hover {
              background-color: $nav-bg;
            }
          }
        }
      }
    }
  }
}
